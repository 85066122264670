import { ref, h } from 'vue'
import { RouterLink } from 'vue-router'
import { NIcon } from 'naive-ui'
import { HomeOutline as HomeIcon } from '@vicons/ionicons5'
// import { UserRegular } from '@vicons/fa'
import {
  AdminPanelSettingsOutlined,
  ModelTrainingSharp,
  GolfCourseFilled,
  PermDataSettingOutlined,
  BookOutlined,
  BookmarkAddedOutlined,
  FeaturedVideoOutlined,
  AltRouteFilled,
  SupervisorAccountTwotone,
  AddLinkFilled
  // SupervisedUserCircleOutlined
  // ArticleRound,
  // BookmarkAddTwotone,
  // AirplayTwotone,
  // AttributionSharp,
  // CommentOutlined,
  // QuestionMarkOutlined,
  // QuestionAnswerOutlined
} from '@vicons/material'

const renderIcon = (icon) => {
  return () => h(NIcon, null, { default: () => h(icon) })
}
export const menuOptions = ref([
  {
    label: () =>
      h(
        RouterLink,
        {
          to: {
            name: 'user-welcome'
          }
        },
        { default: () => '用户首页' }
      ),
    key: 'go-back-home',
    icon: renderIcon(HomeIcon)
  },
  {
    label: '接单赚钱',
    key: 'user-make-money',
    icon: renderIcon(ModelTrainingSharp),
    children: [
      {
        label: () =>
          h(
            RouterLink,
            {
              to: {
                name: 'user-share-project'
              }
            },
            { default: () => '项目共享' }
          ),
        key: 'user-share-project',
        icon: renderIcon(AdminPanelSettingsOutlined)
      },
      {
        label: () =>
          h(
            RouterLink,
            {
              to: {
                name: 'user-dispatch-order'
              }
            },
            { default: () => '派单管理' }
          ),
        key: 'user-dispatch-order',
        icon: renderIcon(GolfCourseFilled)
      }
      // {
      //   label: () =>
      //     h(
      //       RouterLink,
      //       {
      //         to: {
      //           name: 'apply-group'
      //         }
      //       },
      //       { default: () => '加群申请' }
      //     ),
      //   key: 'apply-group',
      //   icon: renderIcon(SupervisedUserCircleOutlined)
      // }
    ]
  },
  {
    label: '团队建设',
    key: 'user-team',
    icon: renderIcon(PermDataSettingOutlined),
    children: [
      {
        label: () =>
          h(
            RouterLink,
            {
              to: {
                name: 'user-team-talent'
              }
            },
            { default: () => '人才库' }
          ),
        key: 'user-team-talent',
        icon: renderIcon(BookOutlined)
      },
      {
        label: () =>
          h(
            RouterLink,
            {
              to: {
                name: 'user-team-resume'
              }
            },
            { default: () => '我的简历' }
          ),
        key: 'user-team-resume',
        icon: renderIcon(BookmarkAddedOutlined)
      }
    ]
  },
  {
    label: '个人信息',
    key: 'user-info',
    icon: renderIcon(FeaturedVideoOutlined),
    children: [
      {
        label: () =>
          h(
            RouterLink,
            {
              to: {
                name: 'user-info'
              }
            },
            { default: () => '个人详情' }
          ),
        key: 'user-info-detail',
        icon: renderIcon(AltRouteFilled)
      },
      {
        label: () =>
          h(
            RouterLink,
            {
              to: {
                name: 'user-info-vip'
              }
            },
            { default: () => 'VIP信息' }
          ),
        key: 'user-info-vip',
        icon: renderIcon(SupervisorAccountTwotone)
      },
      {
        label: () =>
          h(
            RouterLink,
            {
              to: {
                name: 'user-info-setting'
              }
            },
            { default: () => '个人设置' }
          ),
        key: 'user-info-setting',
        icon: renderIcon(AddLinkFilled)
      }
    ]
  }
])
