import axios from 'axios'
import store from '@/store'

const request = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 5000
})

// 请求拦截器
request.interceptors.request.use((config) => {
  // 判断token是否存在
  if (store.getters.token) {
    // 如果token存在 请求的时候带上token
    config.headers.Authorization = 'Bearer ' + store.getters.token
  }
  // 必须返回 config
  return config
})

request.interceptors.response.use(
  (res) => {
    return res
  },
  async (err) => {
    if (err.response.status == 401) {
      store.dispatch('user/logout')
    }

    return Promise.reject(err)
  }
)
export default request
