export const obj2url = (baseurl, data) => {
  let url
  if (data) {
    url = baseurl + '?'
    for (let k in data) {
      url += `${k}=${data[k]}&`
    }
    url = url.slice(0, -1)
  } else {
    url = baseurl
  }
  return url
}

export const totalDuration = (time) => {
  if (time < 0) return '0'
  let hours = String(Math.floor(time / 3600))
  let minutes = String(Math.floor((time % 3600) / 60))
  let seconds = String(Math.floor(time % 60))

  if (Number(hours) < 10) {
    hours = '0' + hours
  }
  if (Number(minutes) < 10) {
    minutes = '0' + minutes
  }
  if (Number(seconds) < 10) {
    seconds = '0' + seconds
  }

  return hours + ':' + minutes + ':' + seconds
}

export const checkBrowser = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera
  // 检查是否是移动端浏览器
  const isMobile =
    /iPhone|iPod|iPad|Android|BlackBerry|Opera Mini|IEMobile|WPDesktop|Windows Phone|MicroMessenger/i.test(
      userAgent
    )
  return isMobile
}

export const checkWechatBrowser = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera
  // 检查是否是移动端浏览器
  return /MicroMessenger/i.test(userAgent)
}
