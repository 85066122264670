import "core-js/modules/es.array.push.js";
import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, withCtx as _withCtx, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-00574dfb"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "container"
};
const _hoisted_2 = {
  class: "logo"
};
import { watch, ref } from 'vue';
import Logo from './components/logo.vue';
import { menuOptions } from './menu';
import { useRoute, useRouter } from 'vue-router';
import storage from '@/utils/storage';
import { checkBrowser } from '@/utils/tools';
export default {
  __name: 'index',
  setup(__props) {
    const route = useRoute();
    const router = useRouter();
    const moduleName = ref(storage.getItem('moduleName') || '欢迎');
    const menuItemName = ref(storage.getItem('menuItemName') || '看板');
    watch(route, to => {
      moduleName.value = to.matched[0].meta.title;
      menuItemName.value = to.meta.title;
      storage.setItem('moduleName', to.matched[0].meta.title);
      storage.setItem('menuItemName', to.meta.title);
    });
    const goToHome = () => {
      router.push('/');
    };
    const showModal = ref(false);
    if (checkBrowser()) {
      router.push('/user/tips');
    }
    return (_ctx, _cache) => {
      const _component_n_menu = _resolveComponent("n-menu");
      const _component_n_layout_sider = _resolveComponent("n-layout-sider");
      const _component_n_breadcrumb_item = _resolveComponent("n-breadcrumb-item");
      const _component_n_breadcrumb = _resolveComponent("n-breadcrumb");
      const _component_n_layout_header = _resolveComponent("n-layout-header");
      const _component_router_view = _resolveComponent("router-view");
      const _component_n_layout_content = _resolveComponent("n-layout-content");
      const _component_n_layout_footer = _resolveComponent("n-layout-footer");
      const _component_n_layout = _resolveComponent("n-layout");
      const _component_n_card = _resolveComponent("n-card");
      const _component_n_modal = _resolveComponent("n-modal");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_n_layout, {
        "has-sider": "",
        class: "layout"
      }, {
        default: _withCtx(() => [_createVNode(_component_n_layout_sider, {
          "content-style": "padding: 24px;"
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createVNode(Logo)]), _createVNode(_component_n_menu, {
            options: _unref(menuOptions)
          }, null, 8, ["options"])]),
          _: 1
        }), _createVNode(_component_n_layout, {
          class: "app-main"
        }, {
          default: _withCtx(() => [_createVNode(_component_n_layout_header, null, {
            default: _withCtx(() => [_createVNode(_component_n_breadcrumb, null, {
              default: _withCtx(() => [_createVNode(_component_n_breadcrumb_item, null, {
                default: _withCtx(() => [_createTextVNode(" 首页")]),
                _: 1
              }), _createVNode(_component_n_breadcrumb_item, null, {
                default: _withCtx(() => [_createTextVNode(_toDisplayString(moduleName.value), 1)]),
                _: 1
              }), _createVNode(_component_n_breadcrumb_item, null, {
                default: _withCtx(() => [_createTextVNode(_toDisplayString(menuItemName.value), 1)]),
                _: 1
              })]),
              _: 1
            }), _createElementVNode("span", {
              class: "logout",
              onClick: goToHome
            }, "网站首页")]),
            _: 1
          }), _createVNode(_component_n_layout_content, {
            "content-style": "padding: 24px;"
          }, {
            default: _withCtx(() => [_createVNode(_component_router_view)]),
            _: 1
          }), _createVNode(_component_n_layout_footer, null, {
            default: _withCtx(() => [_createTextVNode("螺钉众包")]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      }), _createVNode(_component_n_modal, {
        show: showModal.value,
        "onUpdate:show": _cache[0] || (_cache[0] = $event => showModal.value = $event)
      }, {
        default: _withCtx(() => [_createVNode(_component_n_card, {
          style: {
            "width": "50%"
          },
          title: "提醒",
          bordered: false,
          size: "huge",
          role: "dialog",
          "aria-modal": "true"
        }, {
          default: _withCtx(() => [_createTextVNode(" 这是用户中心管理后台，没做优化，不适合移动端使用，建议使用PC浏览器访问 ")]),
          _: 1
        })]),
        _: 1
      }, 8, ["show"])]);
    };
  }
};