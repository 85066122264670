import layout from '@/views/user/layout'

export const privateRoutes = [
  {
    path: '/user',
    component: layout,
    redirect: '/user/welcome',
    name: '用户中心',
    meta: {
      title: '用户中心',
      icon: 'home'
    },
    children: [
      {
        path: '/user/welcome',
        component: () => import('@/views/user/dashboard/welcome'),
        name: 'user-welcome',
        meta: {
          title: '欢迎'
        }
        // children: [
        //   {
        //     path: '/user/info',
        //     component: () => import('@/views/user/info.vue'),
        //     name: 'user-info',
        //     meta: {
        //       title: '个人信息'
        //     }
        //   },
        //   {
        //     path: '/user/course',
        //     component: () => import('@/views/user/course.vue'),
        //     name: 'user-course',
        //     meta: {
        //       title: '我的课程'
        //     }
        //   },
        //   {
        //     path: '/user/resume',
        //     component: () => import('@/views/user/resume.vue'),
        //     name: 'user-resume',
        //     meta: {
        //       title: '我的简历'
        //     }
        //   },
        //   {
        //     path: '/user/crowdsourcing',
        //     component: () => import('@/views/user/crowdsourcing.vue'),
        //     name: 'user-crowdsourcing',
        //     meta: {
        //       title: '派单管理'
        //     }
        //   },
        //   {
        //     path: '/user/project',
        //     component: () => import('@/views/user/project.vue'),
        //     name: 'user-project',
        //     meta: {
        //       title: '项目资源'
        //     }
        //   },
        //   {
        //     path: '/user/setting',
        //     component: () => import('@/views/user/setting.vue'),
        //     name: 'user-setting',
        //     meta: {
        //       title: '个人设置'
        //     }
        //   }
        // ]
      },
      {
        path: '/user/info',
        name: 'user-info',
        redirect: '/user/info/detail',
        meta: {
          title: '个人信息'
        },
        children: [
          {
            path: '/user/info/detail',
            name: 'user-info-detail',
            meta: {
              title: '个人详情'
            },
            component: () => import('@/views/user/info/index.vue')
          },
          {
            path: '/user/info/vip',
            name: 'user-info-vip',
            meta: {
              title: 'vip信息'
            },
            component: () => import('@/views/user/info/vip.vue')
          },
          {
            path: '/user/info/setting',
            name: 'user-info-setting',
            meta: {
              title: '个人设置'
            },
            component: () => import('@/views/user/info/setting.vue')
          }
        ]
      },
      {
        path: '/user/team',
        name: 'user-team',
        redirect: '/user/team/resume',
        meta: {
          title: '团队搭建'
        },
        children: [
          {
            path: '/user/team/resume',
            component: () => import('@/views/user/team/resume.vue'),
            name: 'user-team-resume',
            meta: {
              title: '我的简历'
            }
          },
          {
            path: '/user/team/talent',
            component: () => import('@/views/user/team/talent.vue'),
            name: 'user-team-talent',
            meta: {
              title: '人才库'
            }
          }
        ]
      },
      {
        path: '/user/makemoney',
        name: 'user-makemone',
        redirect: '/user/makemoney/share-project',
        meta: {
          title: '接单赚钱'
        },
        children: [
          {
            path: '/user/makemoney/share-project',
            component: () => import('@/views/user/makemoney/project.vue'),
            name: 'user-share-project',
            meta: {
              title: '项目共享'
            }
          },
          {
            path: '/user/makemoney/order',
            component: () => import('@/views/user/makemoney/crowdsourcing.vue'),
            name: 'user-dispatch-order',
            meta: {
              title: '派单管理'
            }
          }
          // {
          //   path: '/user/makemoney/group',
          //   component: () => import('@/views/user/makemoney/group.vue'),
          //   name: 'apply-group',
          //   meta: {
          //     title: '加群申请'
          //   }
          // }
        ]
      }
    ]
  }
]
