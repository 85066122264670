export default [
  {
    title: '首页',
    href: '/'
  },
  {
    title: '新手教程',
    href: '/video-class'
  },
  {
    title: '会员',
    href: '/vip'
  },
  {
    title: '加群申请',
    href: '/group'
  }
  // {
  //   title: '牛马程序员',
  //   href: 'https://itniuma.com',
  //   target: '_blank'
  // },
  // {
  //   title: '僰课学城',
  //   href: 'https://bokecity.com',
  //   target: '_blank'
  // }

  // {
  //   title: '专栏',
  //   href: '/book-class'
  // },

  // {
  //   title: '视频',
  //   href: '/video-class'
  // }

  // {
  //   title: '更新日志',
  //   href: 'https://itniuma.com/article/course/',
  //   target: '_blank'
  // }
  // {
  //   title: '就业案例',
  //   href: 'https://nodeing.com/article/anli/'
  // },
  // {
  //   title: '文章',
  //   href: 'https://nodeing.com/article/'
  // }
]
