import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'

import { publicRoutes } from './publicRoutes'
import { privateRoutes } from './privateRoutes'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [...publicRoutes, ...privateRoutes]
})

router.beforeEach(async (to, from, next) => {
  if (store.getters.token) {
    // 已经成功登录
    if (!store.getters.hasUserInfo) {
      // 如果没有userInfo 就去获取
      await store.dispatch('user/getUserInfo')
    }
  }
  let blacklist = [
    '/user',
    '/user/info',
    '/user/course',
    '/user/setting',
    '/user/crowdsourcing',
    '/user/resume',
    '/user/tips'
  ]
  if (blacklist.indexOf(to.path) > -1) {
    if (store.getters.token) {
      //如果有token就进入
      next()
    } else {
      next('/')
    }
  } else {
    next()
  }
})

export default router
