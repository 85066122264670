export default {
  namespaced: true,
  state: () => ({
    expand: false,
    isOrderSearch: false,
    searchValue: ''
  }),
  mutations: {
    setExpand(state, flag) {
      state.expand = flag
    },
    setOrderSearchState(state, flag) {
      state.isOrderSearch = flag
    },
    setSearchValue(state, value) {
      state.searchValue = value
    }
  }
}
